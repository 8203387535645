import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "./styles/index.less";
import reportWebVitals from "./reportWebVitals";
import App from "./components/App";
import { QueryClientProvider } from "react-query";
import QueryClientInstance from "./library/queryClient";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
import jwtDecode from "jwt-decode";
import { environmentName, launchDarklyClientId, launchDarklyTenant } from "./config/config";

interface TypeCognitoIdTokenJson {
  name: string;
  email: string;
}

const token = sessionStorage.getItem("id_token");

if (token) {
  const tokenJson = jwtDecode(token ?? "") as TypeCognitoIdTokenJson;
  const environment = environmentName ?? "";
  const clientId = launchDarklyClientId ?? "";
  const tenant = launchDarklyTenant ?? "";

  (async () => {
    const LDProvider = await asyncWithLDProvider({
      clientSideID: clientId,
      context: {
        kind: "user",
        name: tokenJson.name,
        email: tokenJson.email,
        key: `${tenant}_${environment}_${tokenJson.email}`,
        tenant,
        environment,
      },
      flags: {
        "dialler-mode-power": false,
        "dialler-mode-preview-skip": false,
      },
    });
    ReactDOM.render(
      <React.StrictMode>
        <BrowserRouter>
          <QueryClientProvider client={QueryClientInstance.getInstance()}>
            <LDProvider>
              <App />
            </LDProvider>
          </QueryClientProvider>
        </BrowserRouter>
      </React.StrictMode>,
      document.getElementById("root"),
    );
  })();
} else {
  ReactDOM.render(
    <React.StrictMode>
      <BrowserRouter>
        <QueryClientProvider client={QueryClientInstance.getInstance()}>
          <App />
        </QueryClientProvider>
      </BrowserRouter>
    </React.StrictMode>,
    document.getElementById("root"),
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
