import { useCallback } from "react";
import {
  cognitoSupportClientDomain,
  cognitoClientDomain,
  cognitoAppSupportClientId,
  cognitoAppClientId,
  samlProviderName,
} from "src/config/config";
import useAppStore from "./store";

export interface IAppActions {
  doLogin: (isSupportSession: boolean) => Promise<void>;
}

export const useAppActions = (): IAppActions => {
  const { setLoginAsSupport } = useAppStore();

  const doLogin = useCallback(
    async (isSupportSession = false) => {
      const appDomain = isSupportSession ? cognitoSupportClientDomain : cognitoClientDomain;
      const appClientId = isSupportSession ? cognitoAppSupportClientId : cognitoAppClientId;
      isSupportSession && setLoginAsSupport();

      const rUri = `${window.location.protocol}//${window.location.host}`;
      const scope = "openid profile email aws.cognito.signin.user.admin";
      const navigateUrl = `${appDomain}/oauth2/authorize?identity_provider=${samlProviderName}&response_type=code&client_id=${appClientId}&scope=${scope}&redirect_uri=${rUri}`;
      const encodedUrl = encodeURI(navigateUrl);
      window.location.assign(encodedUrl);
    },
    [setLoginAsSupport],
  );

  return { doLogin };
};
