export const environmentName = process.env.REACT_APP_ENVIRONMENT_NAME;

// core login domain
export const cognitoClientDomain = process.env.REACT_APP_COGNITO_APP_CLIENT_DOMAIN;
export const cognitoAppClientId = process.env.REACT_APP_COGNITO_APP_CLIENT_ID;
export const cognitoAppClientSecret = process.env.REACT_APP_COGNITO_APP_CLIENT_SECRET;
export const samlProviderName = process.env.REACT_APP_SAML_PROVIDER_NAME;

// support login domain
export const cognitoSupportClientDomain = process.env.REACT_APP_COGNITO_APP_SUPPORT_CLIENT_DOMAIN;
export const cognitoAppSupportClientId = process.env.REACT_APP_COGNITO_APP_SUPPORT_CLIENT_ID;
export const cognitoAppSupportClientSecret = process.env.REACT_APP_COGNITO_APP_SUPPORT_CLIENT_SECRET;

export const apiEndpointControllers = process.env.REACT_APP_CONTROLLERS_ENDPOINT;
export const apiEndpointWebsocket = process.env.REACT_APP_WEBSOCKET_ENDPOINT;
export const logRocketConfig = {
  isEnabled: process.env.REACT_APP_LOGROCKET_ENABLED,
  appId: process.env.REACT_APP_LOGROCKET_APP_ID,
};

// launchdarkly
export const launchDarklyClientId = process.env.REACT_APP_LD_CLIENT_ID;
export const launchDarklyTenant = process.env.REACT_APP_LD_TENANT;
